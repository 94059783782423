import React, { useState } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import Recording from 'common/assets/videoRecording.png'
import { useTranslation } from "react-i18next";
import Question from "components/Question";

const MaterialStyles = () => ({
  mainWrapper: {
    background: `linear-gradient(180deg, rgba(224, 179, 52, 0.2) 0%, rgba(224, 179, 52, 0) 100%)`,
    padding: '30px 30px'
  },
  recordingIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
   recordingIcon: {
    minHeight: 50,
    minWidth: 50,
    maxHeight: 250
   }
});

const Consent = ({classes, onConsentSubmit}) => {
  const {t} = useTranslation();
  const answerOptions= [
    { key: true, label: 'Yes', displayOrder: 1 },
    { key: false, label: 'No', displayOrder: 2 },
  ]
  const [newEvent, setNewEvent] = useState({})
  const consentQuestion = {
    label: <span dangerouslySetInnerHTML={{ __html: t('consentForRecording') }} />,
    questionType: 'SingleChoiceRadio',
    answerOptions: answerOptions
  };

  const handleChange = (name) => (value) => {
    const data = { [name]: value };
    setNewEvent({
      ...newEvent,
      ...data,
    });

    onConsentSubmit(value);
  };


  const labelStyle = {
    color: '#728D9A',
    fontSize: '16px',
    fontWeight: 'bold'
  };

  return (
    <div className={classes.mainWrapper}>
      <div className={classes.recordingIconContainer}>
        <img src={Recording} className={classes.recordingIcon} alt="recording consent" />
      </div>
      <Question
        question={consentQuestion}
        onAnswered={handleChange("consentForRecording")}
        value={newEvent.consentForRecording}
        labelStyling={labelStyle}
      />
    </div>
  )
}

export default withStyles(MaterialStyles)(Consent)
