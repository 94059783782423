import {Container} from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {userTypes} from "common/helpers";
import MentorMenteeSelector from "components/MentorMenteeSelector";
import MTMarkdown from "components/MTMarkdown";
import Question from 'components/Question/index';
import SignupPager from "components/Question/SignupPager";
import React, {useEffect, useState} from "react";
import SwipeableViews from "react-swipeable-views";
import {useTranslation} from "react-i18next";
import {isEmpty, sortBy} from "lodash";
import {loginType} from "../../common/loginType";


const styles = {
  container: {
    backgroundColor: 'white',
    borderRadius: 10,
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex'
  },
  image: {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  button: {
    marginTop: 31,
    backgroundColor: '#000000',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    color: 'white',
    fontSize: 12
  },
  buttonYellow: {
    marginTop: 31,
    backgroundColor: '#E0B334',
    boxShadow: '0px 2px 4px rgba(224, 179, 52, 0.25)',
    color: 'white',
    fontSize: 12
  },
  buttonBlue: {
    marginTop: 31,
    backgroundColor: '#52B2E2',
    boxShadow: '0px 2px 4px rgba(82, 178, 226, 0.25)',
    color: 'white',
    fontSize: 12,
    alignSelf: 'flex-end'
  },
  error: {
    color: 'red',
    fontSize: 12
  }
};


const Signup = ({
                  profile,
                  signupPageIndex,
                  addFormDetailsToProfile,
                  gettingDeclaration,
                  getProjectList,
                  submitProfileData,
                  width,
                  supportedLanguages,
                  initLanguages,
                  auth
                }) => {
  useEffect(() => {
    if (!supportedLanguages || supportedLanguages.length === 0) initLanguages();
  }, []);

  const {t} = useTranslation();

  const [currentIndex, setCurrentIndex] = useState(signupPageIndex);

  const userEmail = auth.provider === loginType.GOOGLE ? profile.email : auth.email;
  const userEmailDomain = !isEmpty(userEmail) && userEmail.split("@")[1];

  const firstNameQuestion = {
    "label": t('signUpFirstNameLabel'),
    "questionType": "Text",
    "autocomplete": "fname",
    "mandatory": true,
  };
  const lastNameQuestion = {
    "label": t('signUpLastNameLabel'),
    "questionType": "Text",
    "autocomplete": "lname",
    "mandatory": true,
  };
  const phonenumber = {
    "label": t('signUpPhoneNumberLabel'),
    "questionType": "Number",
    "autocomplete": "tel",
    "mandatory": true
  };
  const InstitutionCode = {
    "mandatory": true,
    "label": t('signUpInstitutionCodeLabel'),
    "questionType": "Text",
    "helpText": t('signUpInstitutionCodeHelpText')
  };

  const program = {
    "mandatory": true,
    "label": t('signUpProgramLabel'),
    "questionType": "SingleChoiceDropDown",
    // "helpText": t('programHelpText')
  }

  const primaryLanguageQuestion =
    {
      mandatory: true,
      questionType:
        'SingleChoiceDropDown',
      label:
        t('choosePrimaryLanguage'),
      helpText: t('primaryLanguageHelpText'),
      answerOptions: sortBy(supportedLanguages.filter(language => language.isActive)
        .map(language => {
          return {label: language.language, key: language.languageCode}
        }), 'key')
    };

  const pageTitle = t('signUpMenteeMentorPageTitle');
  const pageDescription = "";

  const handleUserSelection = () => {
    gettingDeclaration();
    setCurrentIndex(currentIndex + 1);
  }
  const handleDeclaration = () => {
    isMentor && getProjectList();
    setCurrentIndex(currentIndex + 1);
  }

  const isMentor = () => {
    return userTypes.MENTOR === profile.userType;
  }

  const handleBack = () => {
    currentIndex > 0 ? setCurrentIndex(currentIndex - 1) : setCurrentIndex(0);
  };


  const handleChange = (name) => (value) => {
    const data = {[name]: value};
    addFormDetailsToProfile(data);
  }


  const handleSubmit = () => {
    submitProfileData();
  };

  const disableProjectsByUserEmailDomain = (projectList, emailDomain) => {
    let foundDomain = false;
    let matchingProjects = [];
    if (!isEmpty(projectList)) {
      projectList.map(project => {
        !isEmpty(project.emailDomains) && project.emailDomains.map(projectDomain => {
          if (projectDomain === emailDomain) {
            foundDomain = true;
            matchingProjects.push(project.key);
          }
        })
      });

      projectList.map(project => {
        if (foundDomain) {
          if (!matchingProjects.includes(project.key)) {
            project.isDisabled = true;
          }
        } else {
          if (!isEmpty(project.emailDomains)) {
            project.isDisabled = true;
          }
        }
      });
    }

    return projectList;
  }

  const getProjectQuestion = () => {
    return (
      {
        ...program,
        answerOptions: disableProjectsByUserEmailDomain(profile.projectList, userEmailDomain)
      }
    )
  }

  const containerMargin = isWidthUp('sm', width) ? "60px" : "5px";


  return (
    <div style={{backgroundColor: '#E7EDF0', height: 'calc(100vh)', display: 'flex', flexDirection: 'column'}}>
      <Container maxWidth={"md"} disableGutters={true} style={{marginTop: containerMargin}}>
        {currentIndex > 0 ? <ArrowBackIcon style={{color: "#52B2E2"}} fontSize={'large'} onClick={handleBack}/> : <div
          style={{minHeight: 40}}/>}
        <SwipeableViews
          axis={'x'}
          index={currentIndex}
          enableMouseEvents
        >
          <SignupPager pageTitle={pageTitle} pageDescription={pageDescription}>
            <MentorMenteeSelector onAnswered={addFormDetailsToProfile}/>
            <Button variant="contained" style={styles.buttonBlue} fullWidth onClick={handleUserSelection}>
              {t('continue')}
            </Button>
          </SignupPager>

          <SignupPager pageTitle={t('signUpDeclarationPageTitle')}>
            <MTMarkdown style={{
              fontSize: '18px',
            }}>{profile.declaration}</MTMarkdown>
            <Button variant="contained" style={styles.buttonBlue} fullWidth onClick={handleDeclaration}>
              {t('iAccept')}
            </Button>
          </SignupPager>

          <SignupPager pageTitle={t('signUpPersonalDetailsPageTitle')}>
            <Question question={firstNameQuestion} onAnswered={handleChange("firstName")} value={profile.firstName}/>
            <Question question={lastNameQuestion} onAnswered={handleChange("lastName")} value={profile.lastName}/>
            {isMentor() ? <Question question={getProjectQuestion()} onAnswered={handleChange("program")}
                                    value={profile.program}/>
              : <Question question={InstitutionCode} onAnswered={handleChange("institutionCode")}
                          value={profile.institutionCode}/>}
            <Question question={primaryLanguageQuestion} onAnswered={handleChange("primaryLanguage")}
                      value={profile.primaryLanguage}/>
            <Question question={phonenumber} onAnswered={handleChange("phoneNumber")} value={profile.phoneNumber}/>
            {profile.signupError && <p style={styles.error}>{profile.signupErrorMessage}</p>}
            <Button variant="contained" style={styles.buttonBlue} fullWidth onClick={handleSubmit}>
              Continue
            </Button>
          </SignupPager>

        </SwipeableViews>

      </Container>
    </div>
  )
}

export default withWidth()(Signup);
